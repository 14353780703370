<template>
  <div>
    <b-table-simple bordered responsive class="container-table-header">
      <b-thead>
        <b-th class="primary-cell">SIGLA</b-th>
        <b-th class="primary-cell">NOMBRE ASIGNATURA</b-th>
        <b-th
          class="primary-cell"
          :class="{
            'd-none':
              isformatCorrectAnswerPartial || isformatCorrectAnswerTransversal,
          }"
          >TIEMPO ASIGNADO</b-th
        >
        <b-th
          class="primary-cell"
          :class="{ 'd-none': isformatCorrectAnswerTransversal }"
          >% PONDERACIÓN</b-th
        >
        <!-- <b-th class="primary-cell">FORMA</b-th> -->
      </b-thead>
      <b-tbody>
        <!-- Codigo -->
        <b-td class="secundary-cell" style="width: 10%">
          <div v-if="matter">{{ matter.code }}</div>
        </b-td>

        <!-- Nombre Asignatura -->
        <b-td class="secundary-cell" style="width: 50%">
          <div v-if="matter">{{ matter.name }}</div>
        </b-td>

        <!-- Tiempo Asignado -->
        <b-td
          class="secundary-cell"
          style="width: 20%"
          :class="{
            'd-none':
              isformatCorrectAnswerPartial || isformatCorrectAnswerTransversal,
          }"
        >
          <div v-if="time_output_format">
            {{ time_output_format.suggested_hours }} Horas
          </div>
          <div v-else>N/A</div>
        </b-td>

        <!-- Ponderación -->
        <b-td
          class="secundary-cell"
          style="width: 20%"
          :class="{ 'd-none': isformatCorrectAnswerTransversal }"
        >
          <div v-if="evaluation">{{ evaluation.weighing }}</div>
          <div v-else>N/A</div>
        </b-td>
        <!-- Forma (Columna formato de salida del ET)-->
        <!-- <b-td class="secundary-cell" style="width: 20%">
          <div v-if="evaluation">{{ evaluation.weighing }}</div>
          <div v-else>N/A</div>
        </b-td> -->
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";

export default {
  name: "HeaderOutputFormat",
  props: {
    evaluation_id: {
      required: true,
      type: Number,
    },
    time_output_format: {
      type: Object,
    },
    isformatCorrectAnswerPartial: {
      type: Boolean,
    },
    isformatCorrectAnswerTransversal: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      evaluations: names.NEW_EVALUATIONS,
      matters: names.MATTERS,
      sections: names.SECTIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    section() {
      if (
        this.evaluation &&
        this.evaluation.scope == 1 &&
        this.evaluation.scope_id != null
      )
        return this.sections.find((x) => x.id == this.evaluation.scope_id);
      else return null;
    },
    egress_profile_matter() {
      if (
        this.evaluation &&
        this.evaluation.scope == 1 &&
        this.evaluation.scope_id != null &&
        this.section &&
        this.section.egress_profile_matter != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      } else if (
        this.evaluation &&
        this.evaluation.scope == 2 &&
        this.evaluation.scope_id != null
      )
        return this.egress_profile_matters.find(
          (x) => x.id == this.evaluation.scope_id
        );
      else return null;
    },
    matter() {
      if (this.evaluation && [1, 2, 3].includes(this.evaluation.scope)) {
        if (this.evaluation.scope == 3 && this.evaluation.scope_id != null)
          return this.matters.find((x) => x.id == this.evaluation.scope_id);
        else if (
          this.evaluation.scope == 2 &&
          this.evaluation.scope_id != null &&
          this.egress_profile_matter
        ) {
          return this.matters.find(
            (x) => x.id == this.egress_profile_matter.matter
          );
        } else if (
          this.evaluation.scope == 1 &&
          this.evaluation.scope_id != null &&
          this.section
        ) {
          if (this.section.matter != null)
            return this.matters.find((x) => x.id == this.section.matter);
          else if (
            this.section.egress_profile_matter != null &&
            this.egress_profile_matter
          ) {
            return this.matters.find(
              (x) => x.id == this.egress_profile_matter.matter
            );
          }
        }
        return null;
      } else return null;
    },
    weighingEvaluation() {
      return this.evaluation_criteria_micros.filter(
        (x) =>
          this.evaluation_criteria_micro_ids.includes(x.id) ||
          this.rubric_criteria.evaluation_criteria_micro == x.id
      );
    },
  },
  methods: {},
};
</script>

<style scoped>
.container-table-header {
  font-size: var(--secondary-font-size);
}
.primary-cell {
  align-items: center;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
  vertical-align: middle;
}
.secundary-cell {
  text-align: center;
  vertical-align: middle;
}
</style>