var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{staticClass:"container-table-header",attrs:{"bordered":"","responsive":""}},[_c('b-thead',[_c('b-th',{staticClass:"primary-cell"},[_vm._v("SIGLA")]),_c('b-th',{staticClass:"primary-cell"},[_vm._v("NOMBRE ASIGNATURA")]),_c('b-th',{staticClass:"primary-cell",class:{
          'd-none':
            _vm.isformatCorrectAnswerPartial || _vm.isformatCorrectAnswerTransversal,
        }},[_vm._v("TIEMPO ASIGNADO")]),_c('b-th',{staticClass:"primary-cell",class:{ 'd-none': _vm.isformatCorrectAnswerTransversal }},[_vm._v("% PONDERACIÓN")])],1),_c('b-tbody',[_c('b-td',{staticClass:"secundary-cell",staticStyle:{"width":"10%"}},[(_vm.matter)?_c('div',[_vm._v(_vm._s(_vm.matter.code))]):_vm._e()]),_c('b-td',{staticClass:"secundary-cell",staticStyle:{"width":"50%"}},[(_vm.matter)?_c('div',[_vm._v(_vm._s(_vm.matter.name))]):_vm._e()]),_c('b-td',{staticClass:"secundary-cell",class:{
          'd-none':
            _vm.isformatCorrectAnswerPartial || _vm.isformatCorrectAnswerTransversal,
        },staticStyle:{"width":"20%"}},[(_vm.time_output_format)?_c('div',[_vm._v(" "+_vm._s(_vm.time_output_format.suggested_hours)+" Horas ")]):_c('div',[_vm._v("N/A")])]),_c('b-td',{staticClass:"secundary-cell",class:{ 'd-none': _vm.isformatCorrectAnswerTransversal },staticStyle:{"width":"20%"}},[(_vm.evaluation)?_c('div',[_vm._v(_vm._s(_vm.evaluation.weighing))]):_c('div',[_vm._v("N/A")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }